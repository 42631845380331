import Vue from 'vue'
import Router from 'vue-router'

import UsuariosRoutes from './routes/usuarios';
import PublicoRoutes from './routes/publico';
import SociosRoutes from './routes/socios';


import { guards } from './guards.js'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  
  routes: [
    ...UsuariosRoutes,
    ...PublicoRoutes,
    ...SociosRoutes,
  ]
})

router.beforeEach(guards);

export default router;
<template>
  <v-dialog v-model="exibir" persistent :max-width="largura" :return-value="resposta">
    <v-card>
      <v-card-title v-show="titulo">{{ titulo }}</v-card-title>

      <v-card-text class="text--primary">
        <slot>{{ texto }}</slot>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <template v-for="(botao, index) in botoes">
          <v-btn v-if="botao == padrao" text :color="cor" ref="padrao" :key="index" :loading="loading" :disabled="loading" @click="responder(botao)" autofocus>{{ botao }}</v-btn >
          <v-btn v-else text :ref="botao" :key="`${index}a`" :disabled="loading" @click="responder(botao)">{{ botao }}</v-btn >
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "z-dialogo-pergunta",

  data() {
    return {
      exibir: false,
      loading: false,
      resposta: false,
      resolve: null,
      reject: null,

      titulo: '',
      largura: '',
      texto: '',
      botoes: [],
      padrao: '',
      cor: '',
    };
  },

  methods: {
    abrir(opcoes) {

      this.titulo = opcoes.titulo || 'Título';
      this.largura = opcoes.largura || '400px';
      this.texto = opcoes.texto || 'Texto';
      this.botoes = opcoes.botoes || ['Não', 'Sim'];
      this.padrao = opcoes.padrao || 'Sim';
      this.cor = opcoes.cor || 'primary';

      this.exibir = true;

      this.$emit("abrir");
      // this.focusBtn();
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    fechar() {
      this.exibir = false;
      this.loading = false;
      this.$emit("fechar");
      this.resolve(false);
    },

    responder(resposta) {
      // this.fechar();
      this.resolve(resposta);
    },

    carregando() {
      this.loading = true;
    },

    // focusBtn() {
    //   setTimeout(() => {
    //     this.$refs.padrao.$el.focus();
    //   }, 300);
    // }
  },
};
</script>

<style scoped>
</style>

import listasApi from "@/app/services/listasApi";

export default {
  namespaced: true,
  state: {
    perfis: [],
    planos: [],
    formaspagamento: []
  },

  getters: {
    perfis(state) {
      return state.perfis.map((perfil) => {
        return {
          text: perfil.descricao,
          value: perfil.id,
        };
      });
    },
  },

  mutations: {
    SET_CLIENTES(state, clientes) {
      state.clientes = clientes;
    },

    SET_PERFIS(state, perfis) {
      state.perfis = perfis;
    },

    SET_PLANOS(state, planos) {
      state.planos = planos;
    },

    SET_FORMASPAGAMENTO(state, formaspagamento) {
      state.formaspagamento = formaspagamento;
    },
  },

  actions: {
    async todas({ commit, dispatch }) {
      dispatch("clientes");
    },

    async clientes({ commit }) {
      let clientes = await listasApi.clientes();
      commit("SET_CLIENTES", clientes);
    },

    async planos({ commit }) {
      let planos = await listasApi.planos();
      commit("SET_PLANOS", planos);
    },

    async formaspagamento({ commit }) {
      let formaspagamento = await listasApi.formaspagamento();
      commit("SET_FORMASPAGAMENTO", formaspagamento);
    },
    async perfis({ commit }) {
      let perfis = await listasApi.perfis();
      commit("SET_PERFIS", perfis);
    },
  },
};

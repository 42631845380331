import createAxios from "@/app/core/AxiosCore";
import config from "@/app/config/app";

const axios = createAxios(config);

function clientes() {
  return axios.get("listas/clientes").then((response) => response.data);
}

function perfis() {
  return axios.get("listas/perfis").then((response) => response.data);
}

function planos() {
  return axios.get("/listas/planos").then((response) => response.data);
}

function formaspagamento() {
  return axios.get("/listas/formaspagamento").then((response) => response.data);
}

export default {
  clientes,
  perfis,
  planos,
  formaspagamento
};

export default [
  
  { 
    path: '/', 
    name: 'Home', 
    component: () => import(/* webpackChunkName: "SociosLista" */ '@/pages/socios/SociosLista'),
    meta: { layout: 'principal' } 
  },
  { 
    path: '/', 
    name: 'SociosLista', 
    component: () => import(/* webpackChunkName: "SociosLista" */ '@/pages/socios/SociosLista'),
    meta: { layout: 'principal' } 
  },

  {
    path: '/socios/add',
    name: 'SocioAdicionar',
    component: () => import(/* webpackChunkName: "SociosForm" */ '@/pages/socios/SociosForm'),
    meta: { layout: 'socios', permissao: 'socio_adicionar' }
  },
  {
    path: '/socios/copy',
    name: 'SocioCopiar',
    component: () => import(/* webpackChunkName: "SociosForm" */ '@/pages/socios/SociosForm'),
    meta: { layout: 'socios', permissao: 'socio_adicionar' }
  },
  {
    path: '/socios/:id/edit',
    name: 'SocioEditar',
    component: () => import(/* webpackChunkName: "SociosForm" */ '@/pages/socios/SociosForm'),
    meta: { layout: 'socios', permissao: 'socio_editar' }
  }
];

<template>
  <v-toolbar flat v-bind="$attrs">
    <v-container :fluid="fluid" py-0>
      <v-row dense justify="center">
        <v-col cols="12" :xl="xl" class="v-toolbar__content" style="height: 64px;">
          <slot></slot>
        </v-col>
      </v-row>
    </v-container>

    <v-progress-linear
        :active="loading"
        :indeterminate="true"
        absolute
        bottom
        color="primary"
        height="1"
      ></v-progress-linear>
  </v-toolbar>
</template>

<script>

  export default {
    name: 'z-centralized-toolbar',
    
    props: {
      fluid: {
        type: Boolean,
        default: false
      },

      xl: {
        type: [String, Number],
        default: '9'
      }
    },

    data() {
      return {
        loading: false
      }
    },
  }
</script>

<style scoped>

</style>
import createAxios from "@/app/core/AxiosCore";
import config from "@/app/config/app";

const axios = createAxios(config);

function usuariosApi() {
  function listar() {
    return axios.get("usuarios").then((response) => response.data);
  }

  function abrir(id) {
    return axios.get("/usuarios/" + id).then((response) => response.data);
  }

  function salvar(vendedor) {
    return axios.post("/usuarios", vendedor).then((response) => response.data);
  }

  function atualizar(vendedor) {
    return axios.put("/usuarios/" + vendedor.id, vendedor).then((response) => response.data);
  }

  function excluir(id) {
    return axios.delete("/usuarios/" + id).then((response) => response.data);
  }

  function perfis() {
    return axios.get("/perfis").then((response) => response.data);
  }

  function salvarPerfil(perfil) {
    return axios.post("/perfis", perfil).then((response) => response.data);
  }

  function atualizarPerfil(perfil) {
    return axios.put("/perfis/" + perfil.id, perfil).then((response) => response.data);
  }

  function excluirPerfil(id) {
    return axios.delete("/perfis/" + id).then((response) => response.data);
  }

  return {
    listar,
    abrir,
    salvar,
    atualizar,
    excluir,
    perfis,
    salvarPerfil,
    atualizarPerfil,
    excluirPerfil,
  };
}

export default usuariosApi();

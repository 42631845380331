import * as icon from "../../../app/icons";

export default [
  {
    id: "todas",
    name: "Acesso Total",
    icon: "mdi-check-all",
    children: [
      {
        id: "cadastros",
        name: "Cadastros",
        icon: icon.CADASTROS,
        visible: true,
        children: [
          { 
            id: "socio", 
            name: "Sócios", 
            icon: icon.CLIENTES, 
            to: { name: 'SociosLista' }, 
            permissao: "socio_acessar",
            disabled: false, 
            children: [
              { id: "socio_acessar", name: "Acessar", icon: icon.ACESSAR, },
              { id: "socio_adicionar", name: "Adicionar", icon: icon.ADICIONAR },
              { id: "socio_editar", name: "Editar", icon: icon.EDITAR },
              { id: "socio_excluir", name: "Excluir", icon: icon.EXCLUIR },
              // { id: "socio_imprimir", name: "Imprimir", icon: icon.IMPRIMIR, },
            ],
          },
        ],
      },
      { 
        id: "sistema", 
        name: "Sistema", 
        icon: icon.SISTEMA,
        visible: true,
        children: [
          { 
            id: "usuarios", 
            name: "Usuários", 
            icon: icon.USUARIOS, 
            to: {name: 'UsuariosLista'},
            permissao: "usuarios_acessar",
            disabled: false,
            children: [
              { id: "usuarios_acessar", name: "Acessar", icon: icon.ACESSAR, },
              { id: "usuarios_adicionar", name: "Adicionar", icon: icon.ADICIONAR },
              { id: "usuarios_editar", name: "Editar", icon: icon.EDITAR },
              { id: "usuarios_excluir", name: "Excluir", icon: icon.EXCLUIR },
            ],
          },
          { 
            id: "permissoes_acessar", 
            name: "Permissões", 
            icon: icon.PERMISSOES, 
            to: {name: 'Permissoes'},
            permissao: "permissoes_acessar",
            disabled: false, 
          },
          // { id: 'configuracoes_acessar', name: 'Configurações' },
        ],
      },
    ],
  },
];

<template>
  <v-app style="background-color: #F2F2F2;">
    <v-slide-y-transition mode='out-in'>
      <router-view></router-view>
    </v-slide-y-transition>
  </v-app>
</template>

<script>
  export default {
    name: 'socios-layout'
  }
</script>

<style scoped>

</style>
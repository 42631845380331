<template>
  <v-text-field
    v-bind="attributes"
    v-model="dateFormatted"
    v-mask="'##/##/####'"
    :readonly="isSm && readonlySm"
    @input="onInput"
    @focus="onFocus"
    @blur="onBlur"
  >
    <template v-slot:append>
      <v-btn icon @click="openDialog">
        <v-icon>mdi-calendar</v-icon>
      </v-btn>

      <v-dialog persistent v-model="exibir" width="290px">    
        <v-date-picker
          v-model="date"
          scrollable
          color="primary"
          :active-picker.sync="datePickerActivePicker"
          :max="(new Date()).toISOString().substring(0, 10)"
          min="1930-01-01"
          @change="onChange"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="exibir = false">Cancelar</v-btn>
        </v-date-picker>
      </v-dialog>
      
    </template>
  </v-text-field>

</template>

<script>
import form_mixin from "./form_mixin";

export default {
  name: "z-date-picker",

  props: {
    value: {
      type: String,
      default: "",
    },

    min: {
      type: String,
      default: "1930-01-01",
    },

    birthday: {
      type: Boolean,
      default: false,
    },

    readonlySm: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dateFormatted: null,
      menu: false,
      isFocused: false,
      max: null,
      exibir: false,
      datePickerActivePicker: null,
    };
  },

  computed: {
    attributes() {
      return { ...this.$attrs, ...this.$v.TEXT_FIELDS  };
    },

    date: {
      get() {
        this.dateFormatted = this.dataBR(this.value);
        return this.value;
      },
      set(val) {
        // this.dateFormatted = this.dataBR(val);
        this.$emit("input", val);
      },
    },

    isSm() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  methods: {

    onInput() {
      if (this.dateFormatted) {
        if (this.dateFormatted.length == 10) {
          this.$emit("input", this.dataUS(this.dateFormatted));
        }
      }
    },

    onFocus(e) {
      if (!this.readonlySm || !this.isSm) {
        // if (!this.isSm) {
          e.target.selectionStart = 0;
          e.target.selectionEnd = e.target.value.length;
          this.isFocused = true;
        // }
      }
    },

    onBlur() {
      if (this.dateFormatted) {
        if (this.dateFormatted.length == 10) {
          this.date = this.dataUS(this.dateFormatted);
        } else if (
          this.dateFormatted.length > 0 &&
          this.dateFormatted.length < 10 &&
          this.date != ""
        ) {
          this.dateFormatted = this.dataBR(this.date);
        } else {
          this.date = this.dateFormatted = "";
        }
      } else {
        this.date = this.dateFormatted;
      }

      this.isFocused = false;
    },

    onChange() {
      this.exibir = false;
      this.$emit("change", this.data);
    },

    dataBR(date) {
      if (!date) return null;

      if (date.length > 10) {
        date = date.substr(0, 10);
      }

      const [year, month, day] = date.split(/[-/]/);
      return `${day}/${month}/${year}`;
    },

    dataUS(date) {
      if (!date) return null;

      const [year, month, day] = date.split(/[-/]/).reverse();
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    openDialog() {
      this.exibir = true;
      this.setYearPicker();
    },

    setYearPicker() {
      if (this.birthday) {
        setTimeout(() => {
          this.max =(new Date()).toISOString().substring(0, 10);
          this.datePickerActivePicker = 'YEAR';
        }, 0);
      } else {
        this.max = null;
      }
    },
  }
};
</script>

<style scoped>
</style>

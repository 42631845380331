import Vue from 'vue'
import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/app/router'
import createStore from '@/app/store'

import '@/app/bootstrap'
import vuetify from '@/app/plugins/vuetify';

import { createPinia, PiniaVuePlugin } from 'pinia';

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

Vue.config.productionTip = false

const store = createStore();

new Vue({
  router,
  store,
  pinia,
  vuetify,
  render: h => h(App)
}).$mount('#app')

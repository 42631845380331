<template>
  <v-app id="inspire" :style="$vuetify.breakpoint.smAndDown? 'background-color: #FFFFFF;': 'background-color: #F2F2F2;'"
  >
    <v-main>
      <v-container fill-height>
        <v-row justify="center" align="center">
          <v-col cols="12" sm="6" md="6" lg="4" xl="3">
            <v-card :color="$vuetify.breakpoint.smAndDown ? 'transparent' : ''" :flat="$vuetify.breakpoint.smAndDown">
              <!-- <v-card-text>
                          <v-row justify="center">
                              <img src="static/organize-verde/96x96.png">
                          </v-row>
                      </v-card-text> -->

              <v-container class="pa-6">
                <v-form v-model="form_valido" ref="form">
                  <v-row justify="center" class="mt-5 mb-8">
                    <h1 class="headline">Acesso ao Sistema</h1>
                  </v-row>
                  <v-row justify="center" class="mb-5">
                    <!-- <v-avatar color="primary" size="140" tile> -->
                    <v-img height="200px" :src="$config.BASE_URL + '/img/waterpark.png'" contain ></v-img>
                    <!-- <v-icon dark size="80">lock</v-icon> -->
                    <!-- </v-avatar> -->
                  </v-row>
                  <v-row justify="center" class="mt-3">
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="usuario.email"
                        ref="usuario"
                        color="primary"
                        label="Usuário"
                        placeholder=" "
                        required
                        autofocus
                        :prepend-icon="$icon.USUARIO"
                        :rules="regras.email"
                        :error="erros.email.length > 0"
                        :error-messages="erros.email"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="usuario.password"
                        ref="senha"
                        color="primary"
                        label="Senha"
                        placeholder=" "
                        required
                        :prepend-icon="$icon.SEGURANCA"
                        :append-icon="mostrar_senha ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="mostrar_senha ? 'text' : 'password'"
                        :rules="regras.password"
                        :error="erros.password.length > 0"
                        :error-messages="erros.password"
                        @click:append="mostrar_senha = !mostrar_senha"
                        @keypress.enter="login"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row justify="center" class="mt-3">
                    <v-col sm="10" md="5" lg="4">
                      <v-btn block color="primary" dark @click="login">Entrar</v-btn>
                    </v-col>
                  </v-row>


                  <v-row>
                    <v-col cols="12" class="text-center text-caption pa-0">
                      {{ this.$config.APP_VERSION }}
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <z-aguardar ref="aguardar" color="primary"></z-aguardar>
  </v-app>
</template>

<script>
// import UsuariosService from '../services/usuarios';

import config from "@/app/config/app";

export default {
  name: 'Login',
  
  data() {
    return {
      usuario: {
        email: "",
        password: "",
      },
      erros: {
        email: [],
        password: [],
      },
      dialog: false,
      form_valido: false,
      regras: {
        email: [(v) => !!v || "Informar o nome do usuário"],
        password: [(v) => !!v || "Informar a senha do usuário"],
      },
      config: config,
      mostrar_senha: false,
    };
  },
  mounted() {
    if (!this.$config.IS_PRODUCTION) {
      this.usuario = {
        email: "admin",
        password: "superadmin123",
      };
    }
  },
  methods: {
    login() {
      // this.ativarRegras();

      this.$nextTick(() => {
        if (this.$refs.form.validate()) {
          this.$refs.aguardar.abrir();
          this.zerarErros();
          this.$auth
            .login(this.usuario)
            .then((response) => {
              this.limparForm();
              this.$router.push({ name: "Home" });
              this.$refs.aguardar.fechar();
            })
            .catch((error) => {
              // this.limparForm();
              this.$auth.logout();
              this.$refs.aguardar.fechar();
              // this.erros.password.push("Usuário ou senha inválidos");
              this.exibirErrosServidor();
              this.$refs.senha.focus();
            });
        }
      });
    },

    zerar() {
      this.usuario = {
        email: "",
        password: "",
      };
    },

    zerarErros() {
      this.erros = {
        email: [],
        password: [],
      };
    },

    ativarRegras() {
      this.regras = {
        email: [
          (v) => !!v || "O campo email é obrigatório",
          (v) =>
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            "O campo e-mail é inválido",
        ],
        password: [
          (v) => !!v || "O campo senha é obrigatório",
          // (v) => v && v.length >= 4 || 'Mínimo 4 caracteres'
        ],
      };
    },

    processarErrosServidor(erros) {
      if (erros.email) {
        this.erros.email = erros.email;
        console.log("Erro no email: ", this.erros.email);
      }
      if (erros.password) {
        this.erros.password = erros.password;
        console.log("Erro no password");
      }
    },

    exibirErrosServidor() {
      this.erros.password.push("Usuário ou senha inválidos");
      setTimeout(() => {
        this.erros.password = [];
      }, 3000);
    },

    limparForm() {
      this.$refs.form.reset();
      this.regras = {};
    },
  },
};
</script>

<style scoped>
.topo {
  margin-top: 50px;
}
</style>

import Login from '@/pages/Login'
import NaoEncontrado from '@/pages/404'
import Configuracoes from '@/pages/sistema/Configuracoes'


export default [
  { 
    path: '*', 
    name: 'NaoEncontrado', 
    component: NaoEncontrado, 
    meta: { layout: 'full', public: true } 
  },
  
  { 
    path: '/login', 
    name: 'Login', 
    component: Login, 
    meta: { layout: 'full', public: true } 
  },

  {
    path: '/configuracoes',
    name: 'Configuracoes',
    component: Configuracoes,
    meta: { layout: 'full', public: true }
  },

]
